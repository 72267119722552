import styled from "styled-components";

const HeroContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: rgb(50, 49, 49);
  background: radial-gradient(
    circle,
    rgba(50, 49, 49, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default HeroContainer;
