import styled from "styled-components";
import { device } from "../commons/device";

interface Props {
  size?: "small" | "medium" | "large";
}

// Need to figure out on the design system level
const sizeMap = {
  small: 400,
  medium: 600,
  large: 800,
};

const Sheet = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 101;
  background-color: #000000;
  overflow: hidden;
  position: fixed;

  @media ${device.tablet} {
    height: ${(props: Props) =>
    props.size ? `${sizeMap[props.size]}px` : "60vh"};
    width: ${(props: Props) =>
    props.size ? `${sizeMap[props.size]}px` : "60vw"};
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
  }
`;

export default Sheet;
