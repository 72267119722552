import FloatingWrapper from "./FloatingWrapper";
import FlexCenter from "../atoms/FlexCenter";
import ConversationIcon from "../atoms/icons/ConversationIcon";

interface Props {
  onClick: () => void;
}
const FloatingContactWidget = (props: Props) => {
  return (
    <button onClick={props?.onClick}>
      <FloatingWrapper>
        <FlexCenter>
          <ConversationIcon width={20} height={20} />
        </FlexCenter>
      </FloatingWrapper>
    </button>
  );
};

export default FloatingContactWidget;
