import styled from "styled-components";

const PricingScreenContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 30%,
    rgba(50, 49, 49, 1) 100%
  );
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default PricingScreenContainer;
