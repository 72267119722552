import HeroContainer from "../containers/HeroContainer";
import GenericContainer from "../containers/GenericContainer";
import ProductName from "../atoms/ProductName";
import LinkAsButton from "../atoms/LinkAsButton";
import Text from "../atoms/Text";
import Spacer from "../layouts/Spacer";
import LocalGemIcon from "../atoms/icons/LocalGemIcon";

const LandingScreen = () => {
  return (
    <HeroContainer>
      <GenericContainer maxWidth={"75%"}>
        <LocalGemIcon width={121} height={159} />
        <ProductName>{"LOCALGEM"}</ProductName>
        <Spacer horizontal={true} size={3} />
        <Text size="medium" weight="normal">
          {"Create a website in 15 minutes"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <Text size="small" weight="light">
          {"Refine it anytime"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <LinkAsButton href="#">{"Get Started"}</LinkAsButton>
      </GenericContainer>
    </HeroContainer>
  );
};

export default LandingScreen;
