import styled from "styled-components";

interface Props {
  width?: number | string;
  height?: number | string;
}
const Image = styled.img`
  width: ${(props: Props) => props.width};
  height: ${(props: Props) => props.height};
`;

export default Image;
