import styled from "styled-components";

const FloatingWrapper = styled.div`
  position: fixed;
  border-radius: 25px;
  background-color: #ffffff;
  right: 1em;
  bottom: 1em;
  height: 50px;
  width: 50px;
`;

export default FloatingWrapper;
