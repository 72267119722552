import styled from "styled-components";

const ProductName = styled.h1`
  font-weight: 100;
  font-size: 16px;
  letter-spacing: 0.3em;
  color: #ffffff;
`;

export default ProductName;
