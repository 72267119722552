import { IconDimension } from "./iconType";

const CancelIcon = (props: IconDimension) => {
  const width = props?.width || "17";
  const height = props?.height || "17";

  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.41418"
        width="22"
        height="2"
        transform="rotate(45 1.41418 0)"
        fill="white"
      />
      <rect
        x="16.9706"
        y="1.41431"
        width="22"
        height="2"
        transform="rotate(135 16.9706 1.41431)"
        fill="white"
      />
    </svg>
  );
};

export default CancelIcon;
