import styled from "styled-components";

const ProductInstructionContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: #424040;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  padding-top: 5em;
  padding-bottom: 5em;
`;

export default ProductInstructionContainer;
