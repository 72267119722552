import styled from "styled-components";

interface Props {
  size?: "xsmall" | "small" | "medium" | "large";
  weight?: "light" | "normal" | "bold";
  color?: string;
}

const sizeMap = {
  xsmall: "10px",
  small: "16px",
  medium: "24px",
  large: "32px",
};

const Text = styled.p`
  color: #ffffff;
  font-size: ${(props: Props) => (props.size ? sizeMap[props.size] : "16px")};
  font-weight: ${(props: Props) => props.weight || "normal"};
  line-height: 1.5;
`;

export default Text;
