if (typeof window !== "undefined") {
  const WebFont = require("webfontloader");

  WebFont.load({
    google: {
      families: ["Roboto:300,400,700", "sans-serif"],
    },
  });
}

import styled from "styled-components";

interface Props {
  fontWeight?: "300" | "400" | "700";
  fontStyle?: "normal";
}

const RobotoFontsContainer = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: ${(props: Props) => props.fontWeight || "400"};
  font-style: ${(props: Props) => props.fontStyle || "normal"};
`;

export default RobotoFontsContainer;
