import PricingScreenContainer from "../containers/PricingScreenContainer";
import Title from "../atoms/Title";
import Card from "../atoms/Card";
import Text from "../atoms/Text";
import Spacer from "../layouts/Spacer";

const PricingScreen = () => {
  return (
    <PricingScreenContainer>
      <Text size="medium" weight="normal">
        {"Pricing"}
      </Text>
      <Spacer horizontal={true} size={3} />
      <Card width={"80vw"}>
        <Text size="medium" weight="normal">
          {"Free Plan"}
        </Text>
        <Text size="xsmall" weight="light">
          {"Own your website for free. Upgrade to a paid plan anytime"}
        </Text>
      </Card>
      <Spacer horizontal={true} size={3} />
      <Card width={"80vw"}>
        <Text size="medium" weight="normal">
          {"Pro - Coming soon"}
        </Text>
        <Text size="xsmall" weight="light">
          {
            "Includes premium features such as map, contact form, Instagram integration"
          }
        </Text>
      </Card>
    </PricingScreenContainer>
  );
};

export default PricingScreen;
