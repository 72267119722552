import { IconDimension } from "./iconType";

const EditIcon = (props: IconDimension) => {
  const width = props?.width || "46";
  const height = props?.height || "46";

  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.25 36.5H16.79C17.0861 36.5017 17.3797 36.4449 17.6538 36.333C17.9279 36.221 18.1773 36.056 18.3875 35.8475L33.9575 20.255L40.3475 14C40.5584 13.7908 40.7258 13.542 40.84 13.2678C40.9542 12.9936 41.013 12.6995 41.013 12.4025C41.013 12.1055 40.9542 11.8114 40.84 11.5372C40.7258 11.263 40.5584 11.0142 40.3475 10.805L30.8075 1.15248C30.5983 0.941596 30.3495 0.774209 30.0753 0.659979C29.8011 0.54575 29.507 0.486938 29.21 0.486938C28.913 0.486938 28.6189 0.54575 28.3447 0.659979C28.0705 0.774209 27.8217 0.941596 27.6125 1.15248L21.2675 7.51998L5.6525 23.1125C5.44397 23.3227 5.27899 23.5721 5.16701 23.8462C5.05504 24.1203 4.99829 24.4139 5 24.71V34.25C5 34.8467 5.23705 35.419 5.65901 35.841C6.08097 36.2629 6.65326 36.5 7.25 36.5ZM29.21 5.92248L35.5775 12.29L32.3825 15.485L26.015 9.11748L29.21 5.92248ZM9.5 25.6325L22.8425 12.29L29.21 18.6575L15.8675 32H9.5V25.6325ZM43.25 41H2.75C2.15326 41 1.58097 41.237 1.15901 41.659C0.737053 42.0809 0.5 42.6532 0.5 43.25C0.5 43.8467 0.737053 44.419 1.15901 44.841C1.58097 45.2629 2.15326 45.5 2.75 45.5H43.25C43.8467 45.5 44.419 45.2629 44.841 44.841C45.2629 44.419 45.5 43.8467 45.5 43.25C45.5 42.6532 45.2629 42.0809 44.841 41.659C44.419 41.237 43.8467 41 43.25 41Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIcon;
