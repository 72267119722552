import styled from "styled-components";

const SalesPitchContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: rgb(75, 75, 75);
  background: linear-gradient(
    315deg,
    rgba(75, 75, 75, 1) 0%,
    rgba(244, 242, 242, 1) 100%
  );
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export default SalesPitchContainer;
