import styled from "styled-components";

interface Props {
  minWidth?: string;
  maxWidth?: string;
  width?: string;
}

const Card = styled.div`
  border-radius: 12px;
  background-color: rgb(113, 113, 113);
  padding: 16px 8px;
  min-width: ${(props: Props) => (props.minWidth ? props.minWidth : "")};
  max-width: ${(props: Props) => (props.maxWidth ? props.maxWidth : "")};
  width: ${(props: Props) => (props.width ? props.width : "")};
`;

export default Card;
