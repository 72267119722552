import FooterContainer from "../containers/FooterContainer";
import Text from "../atoms/Text";
import LinkAsButton from "../atoms/LinkAsButton";
import Spacer from "../layouts/Spacer";
import GenericContainer from "../containers/GenericContainer";

const FooterScreen = () => {
  return (
    <FooterContainer>
      <GenericContainer maxWidth={"75%"}>
        <Text size="medium" weight="normal">
          {"See what you can build in 15 minutes"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <LinkAsButton href="#">{"Get Started"}</LinkAsButton>
      </GenericContainer>
    </FooterContainer>
  );
};

export default FooterScreen;
