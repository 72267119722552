import styled from "styled-components";

const FooterContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, #333333 10.03%, #000000 89.97%);
  height: 40vh;
  justify-content: center;
  align-items: center;
`;

export default FooterContainer;
