import Normalize from "./components/commons/normalize";
import RobotoFontsContainer from "./components/containers/RobotoFontsContainer";
import LandingScreen from "./components/screens/LandingScreen";
import ProductInstructionScreen from "./components/screens/ProductInstructionScreen";
import SalesPitchScreen from "./components/screens/SalesPitchScreen";
import PricingScreen from "./components/screens/PricingScreen";
import FooterScreen from "./components/screens/FooterScreen";
import FloatingContactForm from "./components/widgets/FloatingContactForm";

export default function App() {
  return (
    <>
      <Normalize />
      <RobotoFontsContainer fontWeight="300">
        {/* TODO: pass data as props instead of hard coding in screens */}
        <LandingScreen />
        <ProductInstructionScreen />
        <SalesPitchScreen />
        <PricingScreen />
        <FooterScreen />
        <FloatingContactForm />
      </RobotoFontsContainer>
    </>
  );
}
