import styled, { css } from "styled-components";

interface Props {
  horizontal: boolean;
  size: number;
}
const Spacer = styled.div`
  ${(props: Props) =>
    props.horizontal &&
    css`
      height: ${props.size * 8}px;
    `}

  ${(props: Props) =>
    !props.horizontal &&
    css`
      width: ${props.size * 8}px;
    `}
`;

export default Spacer;
