import styled from "styled-components";

const LinkAsButton = styled.a`
  display: inline-block;
  background-color: #ffffff;
  font-size: 1em;
  margin: 1em;
  padding: 1em;
  border-radius: 25px;
  display: block;
  text-decoration: none;
  &:hover {
    background-color: #d3d3d3;
    color: #000000;
  }
  &:visited {
    color: #000000;
  }
`;

export default LinkAsButton;
