import styled, { css } from "styled-components";

interface Props {
  maxWidth?: string;
}

const GenericContainer = styled.div`
  ${(props: Props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}
`;

export default GenericContainer;
