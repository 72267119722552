import { IconDimension } from "./iconType";

const LocalGemIcon = (props: IconDimension) => {
  const width = props?.width || "121";
  const height = props?.height || "159";

  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 121 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110 78.5L85 92.7894L35 92.7894L10 78.5L35 64.2106L85 64.2106L110 78.5Z"
        fill="#999999"
      />
      <g filter="url(#filter0_f_0_1)">
        <ellipse cx="60.5" cy="144" rx="17.5" ry="4" fill="#111111" />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <path
          d="M110.5 79.2894L60.5 144.289L10.5 79.2894L35.5 65L85.5 65L110.5 79.2894Z"
          fill="#666666"
        />
        <path
          d="M21.5 62.5L10 79L35.5 93.5H86L111 79L98.5 62.5H21.5Z"
          fill="#AAAAAA"
        />
        <path
          d="M98.5 62.5L79.25 73.3253L40.75 73.3253L21.5 62.5L40.75 51.6747L79.25 51.6747L98.5 62.5Z"
          fill="#DDDDDD"
        />
        <path d="M86 93.5H35.5L60.5 144L86 93.5Z" fill="#999999" />
        <path d="M36 93.5L41.5 73.5H79.5L85 93.5H36Z" fill="#C4C4C4" />
      </g>
      <path
        d="M34 3.44352e-06L47.3004 4.65909V41L34 36.3409V3.44352e-06Z"
        fill="#666666"
      />
      <path
        d="M60.6008 0L73.9011 4.65909V41L60.6008 36.3409V0Z"
        fill="#666666"
      />
      <path
        d="M60.6008 0L47.3004 4.65909V41L60.6008 36.3409V0Z"
        fill="#444444"
      />
      <path d="M87 0L73.6996 4.65909V41L87 36.3409V0Z" fill="#444444" />
      <defs>
        <filter
          id="filter0_f_0_1"
          x="37"
          y="134"
          width="47"
          height="20"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="3"
            result="effect1_foregroundBlur_0_1"
          />
        </filter>
        <filter
          id="filter1_d_0_1"
          x="0"
          y="45.6747"
          width="121"
          height="112.615"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default LocalGemIcon;
