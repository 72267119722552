import { IconDimension } from "./iconType";

const CheckIcon = (props: IconDimension) => {
  const width = props?.width || "46";
  const height = props?.height || "46";

  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.12 15.7775L19.4675 25.4525L15.755 21.74C15.5533 21.5045 15.3051 21.3132 15.0259 21.1781C14.7468 21.0431 14.4427 20.9672 14.1329 20.9552C13.823 20.9432 13.514 20.9954 13.2253 21.1086C12.9366 21.2217 12.6743 21.3933 12.455 21.6125C12.2358 21.8318 12.0642 22.094 11.9511 22.3828C11.838 22.6715 11.7857 22.9805 11.7977 23.2904C11.8097 23.6002 11.8856 23.9043 12.0206 24.1834C12.1557 24.4626 12.347 24.7108 12.5825 24.9125L17.87 30.2225C18.0803 30.431 18.3296 30.596 18.6037 30.708C18.8779 30.82 19.1714 30.8767 19.4675 30.875C20.0578 30.8725 20.6234 30.6382 21.0425 30.2225L32.2925 18.9725C32.5034 18.7633 32.6708 18.5145 32.785 18.2403C32.8992 17.9661 32.9581 17.672 32.9581 17.375C32.9581 17.078 32.8992 16.7839 32.785 16.5097C32.6708 16.2355 32.5034 15.9867 32.2925 15.7775C31.8709 15.3584 31.3007 15.1232 30.7063 15.1232C30.1118 15.1232 29.5416 15.3584 29.12 15.7775ZM23 0.5C18.5499 0.5 14.1998 1.8196 10.4997 4.29193C6.79957 6.76427 3.91569 10.2783 2.21272 14.3896C0.509749 18.501 0.0641739 23.025 0.932341 27.3895C1.80051 31.7541 3.94343 35.7632 7.09011 38.9099C10.2368 42.0566 14.2459 44.1995 18.6105 45.0677C22.9751 45.9358 27.4991 45.4903 31.6104 43.7873C35.7217 42.0843 39.2357 39.2004 41.7081 35.5003C44.1804 31.8002 45.5 27.4501 45.5 23C45.5 20.0453 44.918 17.1194 43.7873 14.3896C42.6566 11.6598 40.9992 9.17942 38.9099 7.0901C36.8206 5.00078 34.3402 3.34344 31.6104 2.21271C28.8806 1.08198 25.9548 0.5 23 0.5V0.5ZM23 41C19.4399 41 15.9598 39.9443 12.9997 37.9665C10.0397 35.9886 7.73256 33.1774 6.37018 29.8883C5.0078 26.5992 4.65134 22.98 5.34588 19.4884C6.04041 15.9967 7.75474 12.7894 10.2721 10.2721C12.7894 7.75473 15.9967 6.0404 19.4884 5.34587C22.98 4.65133 26.5992 5.00779 29.8883 6.37017C33.1774 7.73255 35.9886 10.0397 37.9665 12.9997C39.9443 15.9598 41 19.4399 41 23C41 27.7739 39.1036 32.3523 35.7279 35.7279C32.3523 39.1036 27.7739 41 23 41V41Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
