import ProductInstructionContainer from "../containers/ProductInstructionContainer";
import GenericContainer from "../containers/GenericContainer";
import Text from "../atoms/Text";
import BreakLine from "../layouts/BreakLine";
import CheckIcon from "../atoms/icons/CheckIcon";
import FormFillingIcon from "../atoms/icons/FormFIllingIcon";
import SiteIcon from "../atoms/icons/SiteIcon";
import EditIcon from "../atoms/icons/EditIcon";
import Spacer from "../layouts/Spacer";

const ProductInstructionScreen = () => {
  return (
    <ProductInstructionContainer>
      <GenericContainer maxWidth={"75%"}>
        <Text size="medium" weight="normal">
          {"How It Works"}
        </Text>
        <Spacer horizontal={true} size={2} />
        <Text size="small" weight="light">
          {"No technical skills needed"}
        </Text>
        <Spacer horizontal={true} size={4} />

        <CheckIcon width={40} height={40} />
        <Spacer horizontal={true} size={1} />
        <Text size="small" weight="normal">
          {"1. Pick a ready-to-go industry or start a new one"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <BreakLine />
        <Spacer horizontal={true} size={6} />

        <FormFillingIcon width={40} height={40} />
        <Spacer horizontal={true} size={1} />
        <Text size="small" weight="normal">
          {"2. Fill out your business info"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <BreakLine />
        <Spacer horizontal={true} size={6} />

        <SiteIcon width={40} height={40} />
        <Spacer horizontal={true} size={1} />
        <Text size="small" weight="normal">
          {
            "3. Your website is built in a few minutes based on your business info"
          }
        </Text>
        <Spacer horizontal={true} size={3} />
        <BreakLine />
        <Spacer horizontal={true} size={6} />

        <EditIcon width={40} height={40} />
        <Spacer horizontal={true} size={1} />
        <Text size="small" weight="normal">
          {"4. Go live. Refine your website anytime"}
        </Text>
        <Spacer horizontal={true} size={3} />
        <BreakLine />
      </GenericContainer>
    </ProductInstructionContainer>
  );
};

export default ProductInstructionScreen;
