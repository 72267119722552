import { useState, useEffect } from "preact/hooks";
import FloatingContactWidget from "./FloatingContactWidget";
import Sheet from "../atoms/Sheet";
import CancelIcon from "../atoms/icons/CancelIcon";
import CloseModalButton from "../atoms/CloseModalButton";

const FloatingContactForm = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    // Prevent body scrolling when modal is open
    if (show && document.querySelector("body")) {
      document.querySelector("body")!.style.overflow = "hidden";
    }
    if (!show && document.querySelector("body")) {
      document.querySelector("body")!.style.overflow = "visible";
    }
    return;
  }, [show]);

  return (
    <>
      {show ? (
        <Sheet size={"medium"}>
          <CloseModalButton onClick={() => setShow(false)}>
            <CancelIcon height={17} width={17} />
          </CloseModalButton>
        </Sheet>
      ) : (
        <FloatingContactWidget onClick={() => setShow(true)} />
      )}
    </>
  );
};

export default FloatingContactForm;
