import GenericContainer from "../containers/GenericContainer";
import SalesPitchContainer from "../containers/SalesPitchContainer";
import Text from "../atoms/Text";
import Spacer from "../layouts/Spacer";
import Image from "../atoms/Image";
import desktopTabletMobile from "../../assets/images/desktop_tablet_mobile.png";

const SalesPitchScreen = () => {
  return (
    <SalesPitchContainer>
      <Image src={desktopTabletMobile} width={368} height={274} />
      <Spacer horizontal={true} size={5} />
      <Text size="medium" weight="normal">
        {"One Website."}
      </Text>
      <Spacer horizontal={true} size={2} />
      <Text size="medium" weight="normal">
        {"Looks Great on All devices"}
      </Text>
      <Spacer horizontal={true} size={2} />
      <Text size="small" weight="light">
        {"Mobile   •   Tablet   •   Desktop"}
      </Text>
      <Spacer horizontal={true} size={2} />
    </SalesPitchContainer>
  );
};

export default SalesPitchScreen;
